import React from 'react';
import { Select, Typography } from 'antd';

const { Text } = Typography;

interface PositionSelectionProps {
  applyOptions: any;
  selectedLeave: any;
  onSelectPosition: (position: any) => void;
}

const PositionSelection: React.FC<PositionSelectionProps> = ({ applyOptions, selectedLeave, onSelectPosition }) => {
  return (
    <section style={{ marginTop: "10px", padding: "0 10px" }}>
      <div style={{ marginBottom: "10px" }}>
        <Text strong>Position</Text>
        {!applyOptions?.positions ? (
          <div style={{ marginTop: "8px", color: "#999" }}>
            No positions available
          </div>
        ) : applyOptions.positions.length === 1 ? (
          <div style={{ marginTop: "8px" }}>
            {selectedLeave.positionTitle}
          </div>
        ) : (
          <Select
            style={{ width: "100%", marginTop: "8px" }}
            size="large"
            options={applyOptions.positions}
            placeholder="Select a Position"
            value={selectedLeave.positionCode}
            onChange={onSelectPosition}
          ></Select>
        )}
      </div>
    </section>
  );
};

export default PositionSelection;
