import React, { useEffect } from 'react';
import { Typography, Input, Divider, Button } from 'antd';
import theme from '@/styles/customTheme';
import { SaveOutlined } from '@ant-design/icons';
import { GrRevert } from "react-icons/gr";

const { Text } = Typography;
const { TextArea } = Input;

interface CommentsSectionProps {
  isTeamLeave: boolean;
  isEdit: boolean;
  selectedLeave: any;
  setSelectedLeave: (leave: any) => void;
}

const CommentsSection: React.FC<CommentsSectionProps> = ({
  isTeamLeave,
  isEdit,
  selectedLeave,
  setSelectedLeave,
}) => {
  const [showSave, setShowSave] = React.useState(false);
  const [editAbleComments, setEditAbleComments] = React.useState('');
  const [editableMangerComments, setEditableManagerComments] = React.useState('');
  const [showSaveManagerComment, setShowSaveManagerComment] = React.useState(false);
  const commentRef = React.useRef<any>(null);
  const managerCommentRef = React.useRef<any>(null);

  useEffect(() => {
    setEditAbleComments(selectedLeave.comment);
  }, [selectedLeave.comment])

  useEffect(() => {
    setEditableManagerComments(selectedLeave.managerComment);
  }, [selectedLeave.managerComment])

  const saveComment = () => {
    setSelectedLeave({ ...selectedLeave, comment: editAbleComments });
    setShowSave(false);
    commentRef.current?.blur(); // Blur the TextArea after saving
  }

  const revertComment = () => {
    setEditAbleComments(selectedLeave.comment);
    setShowSave(false);
    commentRef.current?.blur();
  }

  useEffect(() => {
    setShowSaveManagerComment(false);
  }, [selectedLeave.managerComment]);

  const saveManagerComment = () => {
    setSelectedLeave({ ...selectedLeave, managerComment: editableMangerComments });
    setShowSaveManagerComment(false);
    managerCommentRef.current?.blur(); // Blur the TextArea after saving
  };

  const revertManagerComment = () => {
    // setSelectedLeave({ ...selectedLeave, managerComment: selectedLeave.managerComment });
    setEditableManagerComments(selectedLeave.managerComment);
    setShowSaveManagerComment(false);
    managerCommentRef.current?.blur();
  };

  return (
    <>
      <section style={{ marginTop: "10px", padding: "0 10px" }}>
        {(isTeamLeave && isEdit) ? (
          <div>
            <div>
              <Text strong>Comments</Text>
            </div>
            
            <Text>{selectedLeave.comment}</Text>
          </div>
        ) : (
          <>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px', alignItems: 'center', height: 25 }}>
            <Text strong >Comments</Text>
            {
              showSave &&
              <div className='mr-4' style={{ fontSize: 16, height: 25, display: 'flex', alignItems: 'center'}} > 
                <GrRevert style={{ color:'#1268b3'}} 
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => revertComment()} /> 
                <SaveOutlined   className='ml-2'  style={{ color:'#1268b3'}} 
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => saveComment()}></SaveOutlined>
              </div>
            }
          </div>
            <TextArea
              ref={commentRef}
              style={{
                marginTop: "8px",
                boxShadow: theme.color.shadow1,
                border: "none",
              }}
              value={editAbleComments}
              onFocus={() => isEdit ? setShowSave(true) : null}
              // onBlur={() => isEdit ? revertComment() : null}
              onChange={(e) => {
                if (!isEdit) {
                  setSelectedLeave({ ...selectedLeave, comment: e.target.value })
                } else {
                  setEditAbleComments(e.target.value)
                }
              }}
              rows={4}
              placeholder="Enter your comment"
            />
          </>
        )}
      </section>
      
      {isTeamLeave && isEdit && (
        <>
          <Divider className="leave-divider" />
          <section style={{ marginTop: "10px", padding: "0 10px" }}>
            <div style={{ height: 25, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Text strong>Manager Comments</Text>

            {
              showSaveManagerComment &&
                <div className='mr-4' style={{ fontSize: 16, height: 25, display: 'flex', alignItems: 'center'}} > 
                  <GrRevert style={{ color:'#1268b3'}} 
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => revertManagerComment()} /> 
                  <SaveOutlined   className='ml-2'  style={{ color:'#1268b3'}} 
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => saveManagerComment()}></SaveOutlined>
                </div>
            }
            </div>
           
            <TextArea
              ref={managerCommentRef}
              style={{
                marginTop: "8px",
                boxShadow: theme.color.shadow1,
                border: "none",
              }}
              value={editableMangerComments}
              onFocus={() => setShowSaveManagerComment(true)}
              onChange={(e) =>
                setEditableManagerComments(e.target.value)
              }
              rows={4}
              placeholder="Enter your comment"
            />
            
          </section>
        </>
      )}
    
    </>
  );
};

export default CommentsSection;
