import React, { useEffect, useState, useMemo, useImperativeHandle, forwardRef, useRef } from 'react';
import { Button, message, Modal, notification, Row, Spin, Typography } from 'antd';
import MyLeaveCard from './MyLeaveCard';
import MyLeaveBalance from './MyLeaveBalance';
import remote from '@/remote/remote';
import ApplyLeave, { ApplyLeaveRef } from '../leaveDetail/ApplyLeave';
import LeaveDetail from '../leaveDetail/LeaveDetail';
import { LeaveHistoryDetails } from '../types';
import { useMyLeaveStore } from '@/store/myLeaveStore';
import { FilterOutlined, LoadingOutlined } from '@ant-design/icons';
import { LEAVE_REQUEST_DATE_FORMAT, LeaveStatus, leaveStatusByCode } from '../constant';
import dayjs, { Dayjs } from 'dayjs';
import MyLeaveHistoryFilter, { DefaultMyHistoryFilter } from './MyLeaveHistoryFilter';
import { getStatusClassNames } from 'antd/es/_util/statusUtils';
import theme from '@/styles/customTheme';

const { Text } = Typography;
const DefaultMyLeaveHistoryParam = {
  StatusCode: 0,
  DateFrom: dayjs().subtract(1, 'year').format(LEAVE_REQUEST_DATE_FORMAT), 
  OrderBy: 'DateSubmitted',
  isAscending: true,
}

interface PaginationType {
  pageNo: number;
  pageSize: number;
  totalItems: number | null;
  totalPages: number | null;
}

const DefaultPagination: PaginationType = { pageNo: 1, pageSize: 15, totalItems: null, totalPages: null };

const MyLeaveTab = forwardRef((props, ref ) => {

  const [myLeaveData, setMyLeaveData] = useState<any[]>([]);
  const [selectedLeave, setSelectedLeave] = useState<any>({});
  const [isLeaveDetailOpen, setIsLeaveDetailOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [hideShowAll, setHideShowAll] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filter, setFilter] = useState(DefaultMyHistoryFilter);
  const [pagination, setPagination] = useState({...DefaultPagination});
  const applyLeaveRef = useRef<ApplyLeaveRef>(null);

  const { state, dispatch, updateSelectedLeave } = useMyLeaveStore(); 
  const user = window.Affinity?.user;
  
  const myStore = useMyLeaveStore();
  const myState = myStore.state;
  const myDispatch = myStore.dispatch;

  useEffect(() => {
    // loadEmployeeLeaveData();
    async function fetchData() {
      dispatch({ type: 'SET_LOADING', payload: true });
      await getLeaveConfig();
      await getMyLeaveHistory(null, true);
      dispatch({ type: 'SET_LOADING', payload: false });
    }

    fetchData();
  }, [])

  const pageRef = useRef(pagination);

  useEffect(() => {
    pageRef.current = pagination;
  }, [pagination])

  useEffect(() => {
    setDataLoading(myState.isLoading);
    setModalLoading(myState.isLoading);
  }, [myState.isLoading])

  useEffect(() => {
    if (filter.status !== LeaveStatus.Pending) {
      setHideShowAll(true);
      setShowAll(true);
    } else {
      setHideShowAll(false);
      setShowAll(false);
    }
  }, [filter])


  const getLeaveConfig = async () => {
    try {
      const res = await remote.getLeaveConfig(user.employeeNo);

      dispatch({ type: 'SET_LEAVE_CONFIG', payload: res });
      dispatch({ type: 'SET_LEAVE_CODE', payload: res.leaveCodes });
    } catch (error) {
      console.error('Failed to get leave config:', error);
    }
  }

  const getMyLeaveHistory = async (paginationInput?: any, newData: boolean = false) => {
    let params: any = {...DefaultMyLeaveHistoryParam,
      StatusCode: filter.status === -1 ? null : filter.status,
      LeaveCode: filter.leaveType === 'all' ? null : filter.leaveType, // todo: change to number??
      DateFrom: filter.dateFrom ? dayjs(filter.dateFrom).format(LEAVE_REQUEST_DATE_FORMAT) : null,
      DateTo: filter.dateTo ? dayjs(filter.dateTo).format(LEAVE_REQUEST_DATE_FORMAT) : null,
      OrderBy: filter.orderBy, // date from, date to, date submitted
      isAscending: filter.isAscending,
    }
    let pageNo = 1;
    if (paginationInput) {
      params.pageNo = paginationInput.pageNo
    }
    try {
      const res = await remote.getMyLeaveHistory(user.employeeNo, params);
      if (!paginationInput) {
        setPagination({
          ...pageRef.current,
          totalPages: res.pageCount,
          totalItems: res.count,
        })
      }
      const newLeaveHistory = res.history.map((leave: LeaveHistoryDetails) => {
        if (leave.tSGroupId !== null) leave.tsGroupId = leave.tSGroupId;
        return {
          employeeName: leave.employeeName,
          employeeNo: leave.employeeNo,
          dateFrom: leave.dateFrom,
          dateTo: leave.dateTo,
          leaveCode: leave.leaveCode,
          codeDescription: leave.codeDescription,
          status: leave.status,
          statusCode: leave.statusCode,
          totalDays: leave.totalDays,
          totalHours: leave.totalHours,
          unitType: leave.unitType,
          comment: leave.comment,
          authorisations: leave.authorisations,
          positionCode: leave.positionCode,
          positionTitle: leave.positionTitle,
          reasonCode: leave.reasonCode,
          reasonDescription: leave.reasonDescription,
          tsGroupId: leave.tSGroupId,
      }});
      if (newData) {
        setMyLeaveData([...newLeaveHistory]);
        return res;
      } else {
        setMyLeaveData([...myLeaveData, ...newLeaveHistory]);
        return res;
      }
    } catch (error) {
      console.error('Failed to get leave history:', error);
    }
  }

  useImperativeHandle(ref, () => ({
    reloadMyLeaveHistory,
    // approveLeave,
  }));

  const reloadMyLeaveHistory = async () => {
    setDataLoading(true);
    setMyLeaveData([]);
    const newPagination = { ...DefaultPagination, pageNo: 1 }
    setPagination(newPagination);
    await new Promise(resolve => setTimeout(resolve, 100));
    await getMyLeaveHistory(null, true);
    setDataLoading(false);
  };

  const loadMoreLeaveHistory = async () => {
    const newPagination = { ...pagination, pageNo: pagination.pageNo + 1 }
    setPagination(newPagination);
    await getMyLeaveHistory(newPagination, false);
  }

  const getBalanceByCode = (leaveCode: string) => {
    return state.leaveCode.find((code: any) => code.leaveCode === leaveCode);
  }

  const editLeave = async () => {
    if (selectedLeave.statusCode === LeaveStatus.Approved) {
      let shouldCancelLeave = false;
      const modal = Modal.confirm({
        title: 'Please Confirm',
        content: 'Approved/paid leave must first be cancelled before you can update it. Continue?',
        icon: null,
        afterClose: async () => {
          if (shouldCancelLeave) {
            let isCancelled = false
            isCancelled = await onCancelRequest(LeaveStatus.Cancelled, false);
            if (isCancelled) {
              closeLeaveDetail();
              reloadMyLeaveHistory();
            }
          }
        },
        footer: () => {
          return (
            <>
              <Button
                type="primary"
                className="btn-green"
                onClick={async () => {
                  shouldCancelLeave = true;
                  modal.destroy();
                }}
              >
                Yes
              </Button>
              <Button
                type="primary"
                className="btn-grey"
                onClick={() => modal.destroy()}
              >
                No
              </Button>
            </>
          );
        },
      });
    } else {
      setIsEdit(true);
    }
    
  }

  const closeLeaveDetail = () => {
    setIsLeaveDetailOpen(false);
    setIsEdit(false);
  }
  
  const moreAction = (selectedLeave: any) => {
    // balance: getBalanceByCode(leave.leaveCode),
    setSelectedLeave(
      {...selectedLeave,
        balance: getBalanceByCode(selectedLeave.leaveCode)
      }
    );
    setIsLeaveDetailOpen(true);
  }

  const onSave = async (statusCode?: number) => {
    if (isEdit) {
      const validation = applyLeaveRef.current?.validate(true);
      if (!validation) {
        console.error('Validation Error', validation);
        return;
      }
    }
    
    setModalLoading(true);
    try {
      const res = await updateSelectedLeave(statusCode !== null ? statusCode : selectedLeave.statusCode);
      if (res) {
        handleMessage(res, 'Leave Updated', 'Cannot Update Leave'); 
        setModalLoading(false);
        closeLeaveDetail();
        reloadMyLeaveHistory();
      } else {
        throw new Error('Failed to update leave');
      }
    } catch (error) {
      errorHandler(error, "Cannot Update Leave", "An error occurred while Update leave");
      setModalLoading(false);
      return
    }
  }

  const onCancelRequest = async (statusCode: number, close: boolean = true) => {
    setModalLoading(true);
    try {
      let isCancelled = false;
      const res = await updateSelectedLeave(statusCode);
      if (selectedLeave.status === "Paid" && selectedLeave.statusCode === LeaveStatus.Approved) {
        isCancelled = handleMessage(res, 'Leave Cancelled', 'Cannot Cancel Leave');
      } else {
        isCancelled = handleMessage(res, 'ssfully Cancelled', 'Cannot Cancel Leave');
      }

      setModalLoading(false);
      if (close) {
        closeLeaveDetail();
      }
      
      reloadMyLeaveHistory();
      return isCancelled;
    } catch (error) {
      console.log(error);
      errorHandler(error, "Cannot Cancel Leave", "An error occurred while Cancel leave");
      setModalLoading(false);
      return false;
    }
  }

  function handleMessage(res: any, successTitle: string, errorTitle: string) {
    let messageArray: string[] = []
    if (res.messages?.length > 0) {
      res.messages.forEach((message: any) => {
        messageArray.push(message.message)
      });
    }
    if (res.response) {
      messageArray.unshift(res.response);
    }

    const notificationConfig = {
      message: res.messages?.[0]?.messageType === 0 ? errorTitle : successTitle,
      description: messageArray.join('\n'),
      duration: 0
    };
  
    if (res.messages?.[0]?.messageType === 0) {
      notification.open({ ...notificationConfig, type: 'error' });
      return false;
    } else if (res.messages?.[0]?.messageType === 1) {
      notification.open({ ...notificationConfig, type: 'warning' });
    } else {
      notification.open({ ...notificationConfig, type: 'success' });
    }
    return true;
  }

  const errorHandler = (error: any, message: string, description: string) => {
    if (!error.response?.data.ModelState) {
      notification.error({
        message: message,
        description: description,
        duration: 0
      });
      return
    }
    const errorDescription = Object.keys(error.response.data.ModelState)
      .reduce((acc, key) => {
        error.response.data.ModelState[key].forEach((message: string) => {
          acc.push(message);
        });
        return acc;
      }, [])
      .join(", ");
    notification.error({
      message: message,
      description: errorDescription,
      duration: 0
    });
  }

  const filterModalOk = async () => {
    setIsFilterOpen(false);
    reloadMyLeaveHistory();
  }

  const onFilterChange = (filter: any) => {
    setFilter(filter);
    setShowAll(true);
  }

  const clear = () => {
    applyLeaveRef.current?.clear();
    
  }

  const toggleShowAll = (newShowAll: boolean) => {
    if (newShowAll === true) {
      filter.status = -1;
    } else {
      filter.status = LeaveStatus.Pending;
    }
    setShowAll(newShowAll);
  }

  const renderViewToggleButton = () => {
    if (hideShowAll) return null;
    return showAll ? (
      <Button
        style={{ marginRight: 10 }}
        type="text"
        onClick={() => toggleShowAll(false)}
      >
        VIEW LESS
      </Button>
    ) : (
      <Button
        style={{ marginRight: 10 }}
        type="text"
        onClick={() => toggleShowAll(true)}
      >
        VIEW ALL
      </Button>
    );
  };
  const renderLoadMoreButton = () => {
    if (!pagination.totalPages || pagination.totalPages <= pagination.pageNo) {
      return null;
    }

    return (
      <div className="text-center">
        <Button
          style={{ margin: "20px 0" }}
          onClick={loadMoreLeaveHistory}>
          Load More
        </Button>
      </div>
    );
  };

  const getStatus = () => {
    if (filter.status === -1) {
      return '';
    }
    return leaveStatusByCode(filter.status)
  }
  return (
    <Spin
        spinning={dataLoading}
        indicator={<LoadingOutlined style={{ fontSize: 42 }} spin />}
      >
    <div style={{ borderRadius: "6px", overflow: "hidden", minHeight: 300 }}>
      
        <Row className="pane-first-row" justify="space-between" align="middle">
          <div>
            {!hideShowAll && (
              <>
                <span className="update-number">{myLeaveData.length}</span>
                <Text>My Updates</Text>
              </>
            )}
            {
              hideShowAll && (
                <Text>Showing {myLeaveData.length} {getStatus()} Leave</Text>
              )
            }
            
          </div>
          <div>
            {/* {renderViewToggleButton()} */}
            <Button
              icon={<FilterOutlined />}
              style={{ marginRight: 20 }}
              onClick={() => setIsFilterOpen(true)}
            >
              Filters
            </Button>
          </div>
        </Row>
        <>
          {myLeaveData.map((leave, index) => (
            <MyLeaveCard
              key={index}
              leave={leave}
              index={index}
              moreAction={moreAction}
            />
          ))}
          {renderLoadMoreButton()}
        </>
        {
          state.leaveCode && state.leaveCode.length > 0 &&
          <MyLeaveBalance />
        }
        
      

      <Modal
        title="Filter"
        width={350}
        open={isFilterOpen}
        onCancel={() => setIsFilterOpen(false)}
        onOk={() => filterModalOk()}
      >
        <MyLeaveHistoryFilter onFilterChange={onFilterChange} />
      </Modal>
      <Modal
        className="full-screen-modal"
        title="Leave Detail"
        open={isLeaveDetailOpen}
        onCancel={closeLeaveDetail}
        destroyOnClose={true}
        footer={
            <>
            {!isEdit && (
              <>
              {selectedLeave.statusCode === LeaveStatus.Cancelled && (
                <Button 
                size={ window.innerWidth <= theme.xsScreen ?'small' : 'middle' }
                style={{ fontSize: window.innerWidth <= theme.xsScreen ? '12px' : '14px' }}
                onClick={() => onSave(LeaveStatus.Pending)} disabled={modalLoading}>
                Submit
                </Button>
              )}
              {selectedLeave.statusCode !== LeaveStatus.CancellRequest && (
                <Button
                  size={ window.innerWidth <= theme.xsScreen ?'small' : 'middle' }
                  style={{ fontSize: window.innerWidth <= theme.xsScreen ? '12px' : '14px' }}
                  onClick={() => editLeave()} disabled={modalLoading}>
                Edit
                </Button>
              )}
              </>
            )}

            {isEdit && (
              <>
              {selectedLeave.statusCode !== LeaveStatus.Declined && (
                <Button type="primary" 
                  size={ window.innerWidth <= theme.xsScreen ?'small' : 'middle' }
                  style={{fontSize: window.innerWidth <= theme.xsScreen ? '12px' : '14px' }}
                  onClick={() => onSave()} disabled={modalLoading}>
                  Submit
                </Button>
              )}
              </>
            )}
            {(selectedLeave.statusCode === LeaveStatus.Pending || selectedLeave.statusCode === LeaveStatus.Declined) && (
              <Button 
                size={ window.innerWidth <= theme.xsScreen ?'small' : 'middle' }
                style={{fontSize: window.innerWidth <= theme.xsScreen ? '12px' : '14px' }}
                type="primary" onClick={() => onCancelRequest(LeaveStatus.Cancelled)} danger disabled={modalLoading}>
                Cancel
              </Button>
            )}
            {selectedLeave.statusCode === LeaveStatus.Approved && (
              <Button 
                size={ window.innerWidth <= theme.xsScreen ?'small' : 'middle' }
                style={{fontSize: window.innerWidth <= theme.xsScreen ? '12px' : '14px' }}
                type="primary" onClick={() => onCancelRequest(LeaveStatus.Cancelled)} danger disabled={modalLoading}>
                Cancel
              </Button>
            )}

            <Button 
              size={ window.innerWidth <= theme.xsScreen ?'small' : 'middle' }
              style={{fontSize: window.innerWidth <= theme.xsScreen ? '12px' : '14px' }}
              onClick={closeLeaveDetail} disabled={modalLoading}>
              Close
            </Button>
            </>
        }
      >
        <Spin 
          indicator={<LoadingOutlined style={{ fontSize: 42 }} spin />}
          spinning={modalLoading}>
          {isEdit ? (
            <ApplyLeave
              ref={applyLeaveRef}
              isTeamLeave={false}
              leave={selectedLeave}
              isNewLeave={false}
              isOpen={isLeaveDetailOpen}
            ></ApplyLeave>
          ) : (
            <LeaveDetail
              isManager={true}
              isTeamLeave={false}
              leave={selectedLeave}
            ></LeaveDetail>
          )}
        </Spin>
      </Modal>
    </div>
    </Spin>
  );
});

export default MyLeaveTab;