import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Row, Select, Button, Typography, Modal, Spin, notification, message } from 'antd';
import { DeleteColumnOutlined, FilterOutlined, LoadingOutlined } from '@ant-design/icons';
import TeamLeaveCard from './TeamLeaveCard';
import remote from '@/remote/remote';
import { useTeamLeaveStore } from '@/store/teamLeaveStore';
import { LeaveHistoryDetails } from '../types';
import TeamLeaveHistoryFilter, { DefaultTeamHistoryFilter, ORDERBY_OPTIONS, STATUS_OPTIONS } from './TeamLeaveHistoryFilter';
import LeaveDetail from '../leaveDetail/LeaveDetail';
import ApplyLeave, { ApplyLeaveRef } from '../leaveDetail/ApplyLeave';
import dayjs from 'dayjs';
import { DefaultTeamLeaveHistoryParam, LEAVE_REQUEST_DATE_FORMAT, LeaveStatus } from '../constant';
import theme from '@/styles/customTheme';

const { Text } = Typography;

// interface TeamLeaveTabProps {
//   teamMembers: { label: string; value: string }[];
//   teamLeaveData: { /* Define the structure of leave data here */ }[];
// }
interface PaginationType {
  current: number;
  pageSize: number;
  totalItems: number | null;
  totalPages: number | null;
}

const DefaultPagination: PaginationType = { current: 1, pageSize: 15, totalItems: null, totalPages: null };


const TeamLeaveTab = forwardRef((props, ref ) => {
  const [teamLeaveData, setTeamLeaveData] = useState<LeaveHistoryDetails[]>([]);
  const [pagination, setPagination] = useState({...DefaultPagination});
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isLeaveDetailOpen, setIsLeaveDetailOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState<LeaveHistoryDetails | null>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<number | null>(null);
  const [filter, setFilter] = useState(DefaultTeamHistoryFilter);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const applyLeaveRef = useRef<ApplyLeaveRef>(null);

  const { state, dispatch, updateSelectedLeave, searchLeaveHistory } = useTeamLeaveStore(); 
  const user = window.Affinity?.user;

  useEffect(() => {
    async function fetchDataInit() {
      setIsLoading(true);
      await loadManagerConfig();
      await loadTeamLeaveHistory();
      setIsLoading(false);
    }
    
    fetchDataInit();
  }, [])

  useEffect(() => {
    dispatch({ type: 'SET_LOADING', payload: isLoading });
  }, [isLoading])

  const loadManagerConfig = () => {
    remote.getLeaveManagerConfig(user.employeeNo)
    .then((res: any) => {
      state.employees = res.employees
      dispatch({ type: 'SET_LEAVE_CONFIG', payload: res });
      dispatch({ type: 'SET_EMPLOYEES', payload: res.employees });
      dispatch({ type: 'SET_LEAVE_CODES', payload: res.leaveCodes });
      dispatch({ type: 'SET_ALL_LEAVE_CODES', payload: res.allCompanyLeaveCodes });
      const employeeList = res.employees.map((employee: any) => ({ isDirect: employee.isDirect, label: employee.employeeName, value: employee.employeeNo }))
      employeeList.unshift({ label: 'All', value: 'all' })
    })
  }
  const employeeOptions = useMemo(() => {
    const employeeList = state.employees.map((employee: any) => ({ 
      isDirect: employee.isDirect,
      label: `${employee.employeeName} (${employee.employeeNo})`,
      value: employee.employeeNo }))
      .filter((employee: any) => {
      // Filter by direct/indirect employees
      if (filter.indirect === false) {
        return employee.isDirect === true
      } else {
        return true
      }
      })
    employeeList.unshift({ isDirect: null, label: 'All', value: 'all' })
    return employeeList
    }, [state.employees, filter.indirect])

  const loadMoreLeaveHistory = async () => {
    setIsLoading(true);
    const newPagination = { ...pagination, current: pagination.current + 1 }
    setPagination(newPagination);
    await loadTeamLeaveHistory(newPagination);
    setIsLoading(false);
  }

  const loadTeamLeaveHistory = async (paginationInput?: any, newData?: boolean, employeeNo?: number) => {
    let pageNo = 1;
    if (paginationInput) {
      pageNo = paginationInput.current
    }

    const res = await searchLeaveHistory(filter, pageNo, employeeNo)
    if (!paginationInput) {
      setPagination({
        ...pagination,
        totalPages: res.totalPages,
        totalItems: res.totalItems,
      })
    }
    if (newData) {
      setTeamLeaveData([...res.leaveHistory]);
    } else {
      setTeamLeaveData([...teamLeaveData, ...res.leaveHistory]);
    }
  }

  useImperativeHandle(ref, () => ({
    reloadLeaveHistory,
    approveLeave,
  }));

  const reloadLeaveHistory = async () => {
    setIsLoading(true);
    setTeamLeaveData([]);
    const newPagination = { ...DefaultPagination, current: 1 }
    setPagination(newPagination);
    await loadTeamLeaveHistory(newPagination, true);
    setIsLoading(false);
  };

  const onSelectEmployee = async (employeeNo: number) => {
    // setSelectedEmployee(employeeNo);
    setFilter({
      ...filter,
      employeeNo: employeeNo,
    })
    
    setTeamLeaveData([]);
    setPagination({...DefaultPagination});
    setIsLoading(true);
    await loadTeamLeaveHistory(null, true, employeeNo);
    setIsLoading(false);
  }

  const openLeaveDetail = (leave: LeaveDetails) => {
    setSelectedLeave(leave);
    setIsLeaveDetailOpen(true);
  }

  const approveLeave = async () => {
    // only validate when editing
    if (isEdit) {
      const validation = applyLeaveRef.current?.validate(true);
      if (!validation) {
        console.error('Validation Error', validation);
        return;
      }
    }
    
    if (!selectedLeave) return;
    setIsModalLoading(true);
    try {
      const res = await updateSelectedLeave(LeaveStatus.Approved);
      if (res.response) {
        handleMessage(res, "Leave Approved", "Cannot Approve Leave");
      }
      setIsModalLoading(false);
      closeLeaveDetail();
      reloadLeaveHistory();
    } catch (error) {
      errorHandler(error, "Cannot Approve Leave", "An error occurred while approving leave");
      setIsModalLoading(false);
      return;
    }
  };

  const approveCancelLeave = async () => {
    if (!selectedLeave) return;
    setIsModalLoading(true);
    try {
      const res = await updateSelectedLeave(LeaveStatus.Approved);
      if (res.response) {
        handleMessage(res, "Leave Cancellation Approved", "Cannot Approve Cancel Request");
      }
      setIsModalLoading(false);
      closeLeaveDetail();
      reloadLeaveHistory();
    } catch (error) {
      errorHandler(error, "Cannot Approve Cancel Request", "An error occurred while approving leave");
      setIsModalLoading(false);
      return;
    }
  }

  const decline = async () => {
    setIsModalLoading(true);
    let isDeclineCancel = selectedLeave?.statusCode === LeaveStatus.CancellRequest;
    try {
      const res = await updateSelectedLeave(LeaveStatus.Declined);
      if (res.response) {
        if (isDeclineCancel) {
          handleMessage(res, "Leave Cancellation Declined", "Cannot Decline Leave");
        } else {
          handleMessage(res, "Leave Declined", "Cannot Decline Leave");
        }
        
        closeLeaveDetail();
      }
      setIsModalLoading(false);
      closeLeaveDetail();
      reloadLeaveHistory();
    } catch (error) {
      errorHandler(error, "Cannot Decline Leave", "An error occurred while declining leave");
      setIsModalLoading(false);
      return;
    }
  };

  const closeLeaveDetail = () => {
    setIsLeaveDetailOpen(false);
    setIsEdit(false);
  }

  // save edit leave
  const saveLeave = async () => {
    const validation = applyLeaveRef.current?.validate(false);
    if (!validation) {
      console.error('Validation Error', validation);
      return;
    }
    if (!selectedLeave) return;
    setIsModalLoading(true);
    try {
      const res = await updateSelectedLeave(selectedLeave.statusCode);
      handleMessage(res, "Leave Updated", "Cannot Update Leave");      
      closeLeaveDetail();
      reloadLeaveHistory();
    } catch (error) {
      console.error('error', error);
      errorHandler(error, "Cannot Update Leave", "An error occurred while update leave");
      setIsModalLoading(false);
    }
    setIsModalLoading(false);
  }

  const editLeave = () => {
    setIsEdit(true);
  }

  const onFilterChange = (filter: any) => {
    setFilter(filter);
  }

  const filterModalOk = async () => {
    setIsFilterOpen(false);
    setTeamLeaveData([]);
    setPagination({...DefaultPagination});
    setIsLoading(true);
    await loadTeamLeaveHistory(null, true);
    setIsLoading(false);
  }

  const getLabelByValue = (options: any[], value: string) => {
    const option = options.find((option) => option.value === value);
    return option ? option.label : value
  }

  const selectedEmployeeName = useMemo(() => {
    const employee = state.employees.find((employee: any) => employee.employeeNo === selectedEmployee);
    return employee ? employee.employeeName : 'All';
  }, [state.employees, selectedEmployee]);

  const employeeFilteredData = useMemo(() => {
    return teamLeaveData.filter((leave) => {
      if (!selectedEmployee) return true;
      return leave.employeeNo === selectedEmployee;
    });
  }, [teamLeaveData, selectedEmployee]);

  function handleMessage(res: any, successTitle: string, errorTitle: string) {
    let messageArray: string[] = []
    if (res.messages?.length > 0) {
      res.messages.forEach((message: any) => {
        messageArray.push(message.message)
      });
    }
    if (res.response) {
      messageArray.unshift(res.response);
    }

    const notificationConfig = {
      message: res.messages?.[0]?.messageType === 0 ? errorTitle : successTitle,
      description: messageArray.join('\n'),
      duration: 0
    };
  
    if (res.messages?.[0]?.messageType === 0) {
      notification.open({ ...notificationConfig, type: 'error' });
      return;
    } else if (res.messages?.[0]?.messageType === 1) {
      notification.open({ ...notificationConfig, type: 'warning' });
    } else {
      notification.open({ ...notificationConfig, type: 'success' });
    }
  }

  const errorHandler = (error: any, message: string, description: string) => {
    if (!error.response.data.ModelState) {
      notification.error({
        message: message,
        description: description,
        duration: 0
      });
      return;
    }
    const errorDescription = Object.keys(error.response.data.ModelState)
      .reduce((acc, key) => {
        error.response.data.ModelState[key].forEach((message: string) => {
          acc.push(message);
        });
        return acc;
      }, [])
      .join(", ");
    notification.error({
      message: message,
      description: errorDescription,
      duration: 0
    });
  }

    useEffect(() => {
      setIsModalLoading(state.isLoading);
    //   // setModalLoading(myState.isLoading);
    }, [state.isLoading])

  return (
    <>
      <div style={{ background: "white", padding: "20px" }}>
        <Row justify="space-between" align="middle">
          <Select
            style={{ minWidth: 160, maxWidth: 220 }}
            placeholder="Select Team Member"
            value={filter.employeeNo}
            options={employeeOptions}
            popupMatchSelectWidth={false}
            allowClear
            onChange={onSelectEmployee}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            showSearch
          />
          <Button icon={<FilterOutlined />} onClick={() => setIsFilterOpen(true)}>Filters</Button>
        </Row>
        <Text
          style={{
            marginTop: "4px",
            display: "inline-block",
            fontSize: "12px",
            textAlign: "center",
          }}
        >
          {
            employeeFilteredData.length === 0 
            ? <span>Showing zero leave applications</span>
            : selectedEmployeeName !== 'All'
              ? <span>Showing {employeeFilteredData.length} leave applications of {selectedEmployeeName} </span>
              : <span>Showing {employeeFilteredData.length} leave applications out of {pagination.totalItems} </span>
          }
          
          with a status of {getLabelByValue(STATUS_OPTIONS, filter.status)}, sorted by {getLabelByValue(ORDERBY_OPTIONS, filter.orderBy)}
        </Text>
      </div>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 42 }} spin />} 
        spinning={state.isLoading}
        size="large" >
        <div style={{ padding: "10px 20px", minHeight: 500 }}>
          {employeeFilteredData.map((leave, index) => {
            return <TeamLeaveCard key={index} leave={leave} index={index} showSubmitted={filter.showSubmitted} showDetail={openLeaveDetail} />
          })}
          {
            (pagination.totalPages && pagination.totalPages > pagination.current) ? (
              <div className="text-center">
                <Button style={{ margin: "20px 0" }} onClick={loadMoreLeaveHistory}>
                  Load More 
                </Button>
              </div>
            ) : null
          }
        </div>
      </Spin>
      <Modal 
        title="Filter"
        width={350}
        open={isFilterOpen}
        onCancel={() => setIsFilterOpen(false)}
        onOk={() => filterModalOk()}>
        <TeamLeaveHistoryFilter onFilterChange={onFilterChange} filter={filter}/>
      </Modal>
      <Modal
        className='full-screen-modal'
        title="Leave Detail"        
        open={isLeaveDetailOpen}
        onCancel={closeLeaveDetail}
        destroyOnClose={true}
        footer={(
            <>
            {
              (selectedLeave?.statusCode === Number(LeaveStatus.Pending) 
              || selectedLeave?.statusCode === Number(LeaveStatus.Cancelled)
              || selectedLeave?.statusCode === Number(LeaveStatus.Declined)) &&
              <Button type='primary'
                size={ window.innerWidth <= theme.xsScreen ?'small' : 'middle' }
            
                style={{
                backgroundColor: '#05aa06',
                fontSize: window.innerWidth <= theme.xsScreen ? '12px' : '14px' 
              }} onClick={() => approveLeave()} disabled={isModalLoading}>Approve</Button>
            }
            {
              selectedLeave?.statusCode === Number(LeaveStatus.CancellRequest) &&
              <Button type='primary' 
                size={ window.innerWidth <= theme.xsScreen ?'small' : 'middle' }
                style={{
                backgroundColor: '#05aa06',
                fontSize: window.innerWidth <= theme.xsScreen ? '12px' : '14px' 
              }} onClick={() => approveCancelLeave()} disabled={isModalLoading}>Approve</Button>
            }
            
            {
              (selectedLeave?.statusCode === LeaveStatus.Pending || selectedLeave?.statusCode === LeaveStatus.Approved || selectedLeave?.statusCode === LeaveStatus.CancellRequest ) &&
              <Button type='primary' danger
                size={ window.innerWidth <= theme.xsScreen ?'small' : 'middle' }
                style={{fontSize: window.innerWidth <= theme.xsScreen ? '12px' : '14px' }}
                onClick={() => decline()} disabled={isModalLoading}>Decline</Button>
            }
            
            {
              isEdit 
              && <Button type='primary' onClick={() => saveLeave()} disabled={isModalLoading}
                size={ window.innerWidth <= theme.xsScreen ?'small' : 'middle' }
                style={{fontSize: window.innerWidth <= theme.xsScreen ? '12px' : '14px' }}
              >Save</Button>
            }
            {
              !isEdit && selectedLeave?.statusCode !== LeaveStatus.CancellRequest &&
              <Button type='primary'
                size={ window.innerWidth <= theme.xsScreen ?'small' : 'middle' }
                
                style={{ fontSize: window.innerWidth <= theme.xsScreen ? '12px' : '14px' , backgroundColor: "#44b5ec"}}
                onClick={() => editLeave()} disabled={isModalLoading}>Edit</Button>
            }
            
            <Button 
              size={ window.innerWidth <= theme.xsScreen ?'small' : 'middle' }
              style={{fontSize: window.innerWidth <= theme.xsScreen ? '12px' : '14px' }}
              onClick={closeLeaveDetail} disabled={isModalLoading}>Close</Button>
            </>
          
        )}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 42 }} spin
          />} spinning={isModalLoading}
          size="large">
          {
            isEdit ? <ApplyLeave ref={applyLeaveRef} isTeamLeave={true} leave={selectedLeave} isOpen={isLeaveDetailOpen} isNewLeave={false}></ApplyLeave>
             : <LeaveDetail isManager={true} isTeamLeave={true} leave={selectedLeave}></LeaveDetail>
          }
          </Spin>
        
      </Modal>
    </>
  );
});

export default TeamLeaveTab;