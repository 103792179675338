import { Select, Input, Button } from 'antd';
import theme from '@/styles/customTheme';
import { useEffect, useMemo, useState, useRef } from 'react';
import { CloseCircleFilled, SaveOutlined } from '@ant-design/icons';
import { GrRevert } from "react-icons/gr";
const { TextArea } = Input;

interface ReasonSectionProps {
  selectedLeave: any;
  selectedLeaveType: any;
  errorToggles: any
  onSelectReason: (value: string) => void;
  setSelectedLeave: (leave: any) => void;
  setErrorToggles: (errorToggles: any) => void;
}

const ReasonSection: React.FC<ReasonSectionProps> = (props: ReasonSectionProps) => {
  const [showSave, setShowSave] = useState(false);
  const [editAbleReason, setEditAbleReason] = useState('');
  const reasonRef = useRef<any>(null);
  const leaveReasonOptions = useMemo(() => {
    if (!props.selectedLeaveType.reasons) {
      return [];
    }
    const reasonArr = props.selectedLeaveType.reasons.map((reason: any) => ({
      value: reason.reasonCode,
      label: reason.description,
    }))
    reasonArr.unshift({ value: '', label: '' });
    return reasonArr;
  }, [props.selectedLeaveType.reasons]);

  useEffect(() => {
    setEditAbleReason(props.selectedLeave.partDayReason);
  }, [props.selectedLeave.partDayReason])

  const onSelectReason = (reason: any) => {
    props.setErrorToggles({ ...props.errorToggles, showReasonError: false });
    if (!reason) {
      props.setSelectedLeave({
        ...props.selectedLeave,
        reasonCode: null,
        reasonDescription: null
      })
      return;
    }
    const selectedReason = props.selectedLeaveType.reasons.find((val: any) => val.reasonCode === reason);
    props.setSelectedLeave({
      ...props.selectedLeave,
      reasonCode: selectedReason.reasonCode,
      reasonDescription: selectedReason.reasonDescription
    })
    return reason
  }
  
  function savePartDayReason(): void {
    console.log('savePartDayReason');
    props.setSelectedLeave({
      ...props.selectedLeave,
      partDayReason: editAbleReason,
    });
    setShowSave(false);
    reasonRef.current?.blur();
  }

  function revertPartDayReason(): void {
    console.log('revertPartDayReason');
    setEditAbleReason(props.selectedLeave.partDayReason);
    setShowSave(false);
    reasonRef.current?.blur();
  }

  return (
    <>
      <section style={{ padding: "0 10px" }}>
        {props.errorToggles.showReasonError && (
          <span className="leave-validate-error">
            <span className="mr-2">
              <CloseCircleFilled />
            </span>
            Please provide a reason why you are taking leave.
          </span>
        )}
        <div className="bold">
          Reason
          {props.selectedLeaveType.mandatoryReason && (
            <span style={{ color: "#e90909", marginLeft: "4px" }}>
              * required
            </span>
          )}
        </div>
        <Select
          style={{ width: "100%", marginTop: "4px" }}
          className='custom-select'
          options={leaveReasonOptions}
          onChange={(reason) => onSelectReason(reason)}
          value={props.selectedLeave.reasonCode}
          notFoundContent="Please select a leave type first "
          allowClear

        />
      </section>
      {props.selectedLeave.showPartDayReason && (
        <section style={{ padding: "10px 10px 0" }}>
          { showSave &&
            <div style={{ height: 25, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div className="bold">Part Day - Start/Finish Times</div>
              <div className='mr-4' style={{ fontSize: 16, height: 25, display: 'flex', alignItems: 'center'}} > 
                <GrRevert style={{ color:'#1268b3'}} 
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => revertPartDayReason()} /> 
                <SaveOutlined   className='ml-2'  style={{ color:'#1268b3'}} 
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => savePartDayReason()}></SaveOutlined>
              </div>
            </div>
          }
          {!showSave &&
            <div className="bold" style={{ height: 25}}>Part Day - Start/Finish Times</div>
          }
          <TextArea
            ref={reasonRef}
            style={{
              marginTop: "8px",
              boxShadow: theme.color.shadow1,
              border: "none",
            }}
            value={editAbleReason}
            onFocus={() => setShowSave(true)}
            onChange={(e) =>
              setEditAbleReason(e.target.value)
            }
            rows={4}
            placeholder="Enter your part day reason"
          />
        </section>
      )}
    </>
  );
};

export default ReasonSection;