import React from 'react';
import { Modal, Button, notification } from 'antd';

export function handleMessage(res: any, successTitle: string, errorTitle: string) {
    let messageArray: string[] = []
    if (res.messages?.length > 0) {
      res.messages.forEach((message: any) => {
        messageArray.push(message.message)
      });
    }
    if (res.response) {
      messageArray.unshift(res.response);
    }

    const notificationConfig = {
      message: res.messages?.[0]?.messageType === 0 ? errorTitle : successTitle,
      description: messageArray.join('\n'),
      duration: 0
    };
  
    if (res.messages?.[0]?.messageType === 0) {
      notification.open({ ...notificationConfig, type: 'error' });
      return;
    } else if (res.messages?.[0]?.messageType === 1) {
      notification.open({ ...notificationConfig, type: 'warning' });
    } else {
      notification.open({ ...notificationConfig, type: 'success' });
    }
  }

