import { LEAVE_REQUEST_DATE_FORMAT, LeaveStatus } from '@/components/constant';
import remote from '@/remote/remote';
import dayjs from 'dayjs';
import { stat } from 'fs';
import { createContext, useReducer, ReactNode, Dispatch, useContext } from 'react';
// Define the shape of the state
interface State {
  leaveConfig: any;
  leaveDetail: any; // leaveDetail received from API
  leaveBalance: any;
  leaveCode: any; // Added leaveCode
  selectedLeave?: any;
  selectedLeaveUnit?: any; // Added selectedLeaveUnit
  user: any; // Added user
  isLoading: boolean; // Added isLoading
}

// Define the initial state
const initialState: State = {
  leaveConfig: {},
  leaveDetail: {},
  leaveBalance: {},
  leaveCode: [], // Added leaveCode
  selectedLeave: {},
  selectedLeaveUnit: {}, // Added selectedLeaveUnit
  user: {}, // Added user
  isLoading: false // Added isLoading
};

// Define action types
type Action =
  | { type: 'SET_LEAVE_CONFIG'; payload: any }
  | { type: 'SET_LEAVE_DETAIL'; payload: any }
  | { type: 'SET_LEAVE_BALANCE'; payload: any }
  | { type: 'SET_LEAVE_CODE'; payload: any }
  | { type: 'SET_SELECTED_LEAVE'; payload: any }
  | { type: 'SET_SELECTED_LEAVE_UNIT'; payload: any } // Added SET_SELECTED_LEAVE_UNIT
  | { type: 'SET_USER'; payload: any }
  | { type: 'SET_LOADING'; payload: boolean }; // Added SET_LOADING

// Create the reducer
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_LEAVE_CONFIG':
      return { ...state, leaveConfig: action.payload };
    case 'SET_LEAVE_DETAIL':
      return { ...state, leaveDetail: action.payload };
    case 'SET_LEAVE_BALANCE':
      return { ...state, leaveBalance: action.payload };
    case 'SET_LEAVE_CODE': // Added case for SET_LEAVE_CODE
      return { ...state, leaveCode: action.payload };
    case 'SET_SELECTED_LEAVE':
      return { ...state, selectedLeave: action.payload };
    case 'SET_SELECTED_LEAVE_UNIT': // Added case for SET_SELECTED_LEAVE_UNIT
      return { ...state, selectedLeaveUnit: action.payload };
    case 'SET_USER': // Added case for SET_USER
      return { ...state, user: action.payload };
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

interface MyLeaveStoreContextProps {
  state: State;
  dispatch: Dispatch<Action>;
  applySelectedLeave: (statusCode?: LeaveStatus) => any;
  updateSelectedLeave: (statusCode?: LeaveStatus) => any;
}

// Create context
const MyLeaveStoreContext = createContext<MyLeaveStoreContextProps>({
  state: initialState,
  dispatch: () => undefined,
  applySelectedLeave: (statusCode?: LeaveStatus) => undefined,
  updateSelectedLeave: (statusCode?: LeaveStatus) => undefined,
});

interface MyLeaveStoreProviderProps {
  children: ReactNode;
}

// Create a provider component
const MyLeaveStoreProvider = ({ children }: MyLeaveStoreProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const applySelectedLeave = async (statusCode: LeaveStatus = LeaveStatus.Pending) => {
    // "EmployeeNo":"64259","PositionCode":"92000198","LeaveCode":"07","DateFrom":"22/Oct/2024","DateTo":"22/Oct/2024","ReasonCode":"Carers","TotalUnits":"7.6","SubmittedBy":43749,"Authorisations":[{"PositionCode":"92000198","SubmittedTo":"41931"}],"Comment":"","StatusCode":0,"UnitType":"H","Reply":null,"Units":[{"Date":"2024-10-22T00:00:00","PositionCode":"92000198","Hours":7.6,"HoursWorkSched":7.6,"Days":1}],"PartDayReason":""
    // dispatch({ type: 'SET_LEAVE_DETAIL', payload: selectedLeave });
    // state.isLoading = true;
    dispatch({ type: 'SET_LOADING', payload: true });
    const units = formatDaysIntoUnits(state.selectedLeave.days);
    const dateFrom = dayjs(state.selectedLeave.dateFrom).format(LEAVE_REQUEST_DATE_FORMAT);
    const dateTo = dayjs(state.selectedLeave.dateTo).format(LEAVE_REQUEST_DATE_FORMAT);
    const params = {
      employeeNo: state.selectedLeave.employeeNo,
      positionCode: state.selectedLeave.positionCode,
      leaveCode: state.selectedLeave.leaveCode,
      dateFrom: dateFrom,
      dateTo: dateTo,
      reasonCode: state.selectedLeave.reasonCode,
      hasAttachment: state.selectedLeave.attachments?.length > 0,
      submittedBy: state.selectedLeave.employeeNo,
      authorisations: state.selectedLeave.authorisations.map((auth: any) => ({
        submittedTo: auth.employeeNo.toString(), 
        // positionCode: auth.positionCode
        positionCode: state.selectedLeave.positionCode // Notes: use employee's position code
      })),
      comment: state.selectedLeave.comment,
      statusCode: statusCode,
      unitType: state.selectedLeave.unitType,
      reply: null,
      units: units,
      totalUnits: state.selectedLeave.unitType === 'H' ? state.selectedLeave.totalHours : state.selectedLeave.totalDays,
      partDayReason: state.selectedLeave.partDayReason
    }
    try {
      const res = await remote.createLeaveApplication(state.selectedLeave.employeeNo, params)
      if (res.data) {
        res.data.tsGroupId = res.data.tsGroupId || res.data.tSGroupId;
        if (state.selectedLeave.attachments?.length > 0) {
          // todo: move upload attachment to standalone function;
          // so that it will have it own error message handling
          state.selectedLeave.attachments.forEach((attachment: any) => {
            try {
              remote.createLeaveAttachment(state.selectedLeave.employeeNo, res.data.tsGroupId, attachment)
            } catch (error) {
              throw error;
            }        
          }); 
        }
      }
      dispatch({ type: 'SET_LOADING', payload: false });
      return res;
    } catch (error) {
      console.error('Error creating leave application:', error);
      dispatch({ type: 'SET_LOADING', payload: false });
      throw error;
    }
  }

  const updateSelectedLeave = async (leaveStatus = '0') => {
    console.log('updateSelectedLeave', state.selectedLeave);
    const leave = state.selectedLeave;
    // const units = formatDaysIntoUnits(state.selectedLeave.days);
    let leaveUnits = [];
    if (state.selectedLeaveUnit.units && state.selectedLeaveUnit.units.length > 0) {
      leaveUnits = state.selectedLeaveUnit.units;
    } else {
      leaveUnits = state.selectedLeaveUnit
    }
    const dateFrom = dayjs(state.selectedLeave.dateFrom).format(LEAVE_REQUEST_DATE_FORMAT);
    const dateTo = dayjs(state.selectedLeave.dateTo).format(LEAVE_REQUEST_DATE_FORMAT);
    dispatch({ type: 'SET_LOADING', payload: true });

    const param = {
      payCode: leave.leaveCode,
      calcUnit: leave.unitType,
      dateFrom: dateFrom,
      dateTo: dateTo,
      employeeNo: leave.employeeNo,
      reason: leave.reasonCode,
      managerNotes: leave.managerComment,
      empComment: leave.comment,
      partDayReason: leave.partDayReason,
      timeLastModified: leave.timeLastModified,
      tsGroupId: leave.tsGroupId,
      hasAttachment: leave.attachments?.length > 0,
      leaveUnits: leaveUnits
      .filter((unit: any) => unit.totalHoursAppliedFor > 0 || unit.hoursAppliedFor > 0 )
      .map((unit: any) => {
        if (unit.positionUnits?.length > 0) {
          Object.keys(unit.positionUnits[0]).forEach(key => {
            unit[key] = unit.positionUnits[0][key];
          });
        }
        return {
          daysAppliedFor: unit.totalDaysAppliedFor ? unit.totalDaysAppliedFor : unit.daysAppliedFor,
          defaultHoursWorkScheduled: null,
          hoursAppliedFor: unit.totalHoursAppliedFor ? unit.totalHoursAppliedFor : unit.hoursAppliedFor,
          hoursWorkSched: unit.hoursWorkScheduled,
          isPublicHoliday: false,
          leaveDate: unit.date,
          positionCode: state.selectedLeave.positionCode,
          publicHolidayName: "",
          tsGroupId:  leave.tsGroupId
        }
      }),
      leaveStatus: leaveStatus,
    };
    try {
      const res = await remote.updateLeaveV2(state.selectedLeave.tsGroupId, param)
      dispatch({ type: 'SET_LOADING', payload: false });
      return res;
    } catch (error) {
      dispatch({ type: 'SET_LOADING', payload: false });
      throw error;
    }
  }

  const formatDaysIntoUnits = (days: []) => {
    let units = days
    .filter((day: any) => (day.totalHoursAppliedFor > 0 || day.hoursAppliedFor > 0 ))
    .map((day: any) => {
      const daysUnit = day.positionUnits[0]
      return {
        "Date": day.date,
        "PositionCode": state.selectedLeave.positionCode,
        "Hours": day.totalHoursAppliedFor,
        "HoursWorkSched": daysUnit.hoursWorkScheduled,
        "Days": day.totalDaysAppliedFor
      }
    })
    return units;
    // dispatch({ type: 'SET_SELECTED_LEAVE', payload: { ...state.selectedLeave, units } });
  }

  return (
    <MyLeaveStoreContext.Provider value={{ state, dispatch, applySelectedLeave, updateSelectedLeave }}>
      {children}
    </MyLeaveStoreContext.Provider>
  );
};

// Custom hook to use the store context more easily
const useMyLeaveStore = () => {
  const context = useContext(MyLeaveStoreContext);
  if (!context) {
    throw new Error('useMyLeaveStore must be used within a MyLeaveStoreProvider');
  }
  return context;
};

export { MyLeaveStoreProvider, useMyLeaveStore };
