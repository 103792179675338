import React, { useState } from 'react';
import { Button, Card, Col, Collapse, DatePicker, DatePickerProps, List, Row, Spin, Typography } from 'antd';
const { Title } = Typography;
import dayjs, { Dayjs } from 'dayjs';
import remote from '@/remote/remote';
import { LoadingOutlined } from '@ant-design/icons';
import { DISPLAY_DATE_FORMAT, ESTIMATE_LEAVE_CODE_LIST } from '../constant';
import theme from '@/styles/customTheme';

// 07, 09, 10, 11, 12, 13
const MyEstimateLeave: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [showList, setShowList] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [employeeCodeBalances, setEmployeeCodeBalances] = useState<any[]>([]);
  const [codeBalances, setCodeBalances] = useState<any[]>([]);
  const user = window.Affinity?.user;

  const handleDateChange: DatePickerProps['onChange'] = (date, dateString) => {
    setSelectedDate(date as Dayjs);
  };

  const onShowMyEstimate = async () => {
    const today = selectedDate.format('DD-MMM-YYYY');

    let params = {
      "dateTo": today,
      "includeIndirect": false,
      // "employeeNo": user.employeeNo,
      // "leaveCode": "07",
      "pageNo": 1
    }
    setIsLoading(true);
    try {
      const res = await remote.getEmployeeLeaveBalance(user.employeeNo, params)      
      const codeBalances = res.componentBalances[0].codeBalances.filter((item: any) => {
        let included = ESTIMATE_LEAVE_CODE_LIST.includes(item.leaveCode)
        if (item.displayBalance === false) {
          included = false
        }
        return included
      }
        
      );
      setCodeBalances(codeBalances);
      setShowList(true);
    } catch (error) {
      console.log('error', error);
    }
    setIsLoading(false);
    
  };

    function getTotalDisplay(item: any): React.ReactNode {
      const totalVal = item.unitType === "H" ? item.totalHours : item.totalDays
      if (totalVal < 0) {
        return <span style={{ color: theme.color.redMobile}}>- {Math.abs(totalVal)}</span>
      } else {
        return totalVal
      }
    }
  return (
    <Card style={{ borderRadius: "8px" }}>
      <Title level={4}>My Estimated Leave</Title>
      <DatePicker
        style={{ marginTop: "16px" }}
        onChange={handleDateChange}
        value={selectedDate}
        minDate={dayjs()} // today
      />
      <Button
        type="primary"
        onClick={onShowMyEstimate}
        style={{ marginLeft: 8 }}
      >
        Show
      </Button>
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 42 }} spin />}
        spinning={isLoading}
        size="large"
      >
        {/* {showList && ( */}
        <Collapse
          ghost
          style={{ marginTop: 16, minHeight: isLoading ? "200px" : "auto" }}
          className="estimate-leave-collapse"
          items={codeBalances.map((item) => ({
            key: item.leaveCode,
            label: (
              <Row>
                <Col span={12}>{item.codeDescription}</Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  { item.unitType === 'H' ? item.totalHours : item.totalDays }
                </Col>
              </Row>
            ),
            children: (
              <div>
                <Row style={{ marginBottom: "10px", fontWeight: "bold" }}>
                  <Col span={18}>Breakdown</Col>
                  <Col span={6} style={{ textAlign: "right" }}>
                    { item.unitType === 'H' ? 'Hours' : 'Days' }
                  </Col>
                </Row>
                <Row style={{ marginBottom: "10px" }}>
                  <Col span={18}>Leave balance at last period end </Col>
                  <Col span={6} style={{ textAlign: "right" }}>
                    {item.entitlement}
                  </Col>
                </Row>
                <Row style={{ marginBottom: "10px" }}>
                  <Col span={12}>Add leave accruals</Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    + {(item.postProjectedAccruals + item.accrual).toFixed(2)}
                  </Col>
                </Row>
                {
                  item.leaveItems && (
                    <List
                  dataSource={item.leaveItems}
                  grid={{ gutter: 10, column: 1 }}
                  renderItem={(leaveItem: any) => (
                    <List.Item>
                      <Row>
                        {leaveItem?.isCancelledOrDeclinedLeavePaidInAdvance ? (
                          <>
                            <Col span={12}>
                              Credit cancelled / declined leave booked on{" "}
                              {dayjs(leaveItem.dateFrom).format(
                                DISPLAY_DATE_FORMAT
                              )}
                            </Col>
                            <Col span={12} style={{ textAlign: "right" }}>
                              + {leaveItem.units}
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col span={12}>
                              Subtract leave booked on {" "}
                              {dayjs(leaveItem.dateFrom).format(
                                DISPLAY_DATE_FORMAT
                              )}
                            </Col>
                            <Col
                              span={12}
                              style={{
                                textAlign: "right",
                                color: theme.color.redMobile,
                              }}
                            >
                              - {leaveItem.units}
                            </Col>
                          </>
                        )}
                      </Row>
                    </List.Item>
                  )}
                ></List>
                  )
                }

                
                <Row style={{ marginBottom: "10px", fontWeight: "bold" }}>
                  <Col span={18}>
                    Total estimated leave available on{" "}
                    {selectedDate.format("DD/MM/YYYY")}
                  </Col>
                  <Col span={6} style={{ textAlign: "right" }}>
                    {/* { item.unitType === 'H' ? item.totalHours : item.totalDays } */}
                    {getTotalDisplay(item)}
                  </Col>
                </Row>
              </div>
            ),
          }))}
          defaultActiveKey={["1"]}
        />
        {/* )} */}
      </Spin>
    </Card>
  );
};

export default MyEstimateLeave;