import React, { useEffect, useState, useMemo } from 'react';
import { DatePicker, Button, List, Row, Col, Card, Collapse, Typography, Select, DatePickerProps } from 'antd';
import dayjs from 'dayjs';

const { Title } = Typography;
interface CalendarProps {
  canManage: boolean;
}

const LeaveCalendar: React.FC<CalendarProps> = (props: CalendarProps) => {
  return (
    <div>
      
      {
        props.canManage &&
        <>
          <h3 style={{margin: '10px 0 0 0', padding: '10px 0 5px 10px', background: 'white'}}>Team Leave Calendar</h3>
          <iframe
            src="https://leave-ui.testaffinitylogon.com/manager-team-calendar"
            width="100%"
            height="500"
            style={{ border: 'none' }}
            title="Leave UI"
            allowFullScreen
          ></iframe>
        </>
      }
      
      <h3 style={{margin: '10px 0 0 0', padding: '10px 0 5px 10px', background: 'white'}}>Leave Calendar</h3>
      <iframe
        src="https://leave-ui.testaffinitylogon.com/employee-team-calendar"
        width="100%"
        height="420"
        style={{ border: 'none' }}
        title="Leave UI"
        allowFullScreen
      ></iframe>

    </div>
  );
};

export default LeaveCalendar;