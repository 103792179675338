import React, { useEffect, useRef, useState } from 'react';
import { Button, Tabs, Card, Row, Col, Divider, Typography, Badge, Modal, Select, notification, Spin} from 'antd';
import ApplyLeave, { ApplyLeaveRef } from './leaveDetail/ApplyLeave';
import MyLeaveTab from './myLeave/MyLeaveTab';
import TeamLeaveTab from './teamLeave/TeamLeaveTab';
import { LoadingOutlined } from '@ant-design/icons';
import { useMyLeaveStore } from '@/store/myLeaveStore';
import EstimateLeave from './estimateLeave/EstimateLeave';
import { useTeamLeaveStore } from '@/store/teamLeaveStore';
import LeaveCalendar from './calendar/LeaveCalendar';
import { LeaveStatus } from './constant';
import theme from '@/styles/customTheme';


const { Title, Text } = Typography;
const { TabPane } = Tabs;

// todo: fix enum to include estimated leave and leave calendar
enum LeavePan {
  myLeave = 'My Leave',
  teamLeave = 'Team Leave'
}

interface LeaveProps {
  canManageLeave: boolean;
}

const Leave = (props: LeaveProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [leavePan, setLeavePan] = useState<LeavePan>(props.canManageLeave ? LeavePan.teamLeave : LeavePan.myLeave);
  const [leavePan, setLeavePan] = useState<LeavePan>(LeavePan.teamLeave);
  const [userLoaded, setUserLoaded] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const teamLeaveTabRef = useRef<{ reloadLeaveHistory: () => void }>(null);
  const myLeaveTabRef = useRef<{ reloadMyLeaveHistory: () => void }>(null);
  const applyLeaveRef = useRef<ApplyLeaveRef>(null);
  
  // const [teamLeaveList, setTeamLeaveList] = useState([]);

  // add useStore
  const store = useTeamLeaveStore(); 
  const state = store.state;
  const myStore = useMyLeaveStore();
  const myState = myStore.state;
  const myDispatch = myStore.dispatch;

  useEffect(() => {
    if (myState.isLoading || store.state.isLoading) {
      setButtonLoading(true);
      setModalLoading(true);
    } else {
      setButtonLoading(false);
      setModalLoading(false);
    }
  }, [myState.isLoading, store.state.isLoading])

  useEffect(() => {
    if (props.canManageLeave) {
      setLeavePan(LeavePan.teamLeave);
    } else {
      setLeavePan(LeavePan.myLeave);
    } 
  }, [])


  const showCreateLeave = () => {
    showModal();
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async (isApprove: boolean) => {
    const validation = applyLeaveRef.current?.validate(isApprove);
    if (!validation) {
      console.error('Validation Error', validation);
      return;
    }
    setModalLoading(true);
    if(leavePan === 'Team Leave') {      
      await teamSaveLeave(isApprove);
    } else {
      // my leave
      await mySaveLeave(isApprove);
    }
    setModalLoading(false);
  };

  const teamSaveLeave = async (isApprove: boolean) => {
    // team leave
    let res = null;
    try {
      res = await store.applySelectedLeave(isApprove ? LeaveStatus.Approved : LeaveStatus.Pending);
    } catch (error) {
      console.log('Apply Team Leave Failed error', error);
      notification.error({
        message: isApprove ? "Cannot Approve Leave" : "Cannot Submit Leave",
        duration: 0
      });
      return;
    } 
    errorHandler(res, isApprove, teamLeaveTabRef?.current?.reloadLeaveHistory);
  }

  const mySaveLeave = async (isApprove: boolean) => {
    let res = null;
    try {
      res = await myStore.applySelectedLeave(isApprove ? LeaveStatus.Approved : LeaveStatus.Pending);
    } catch (error) {
      console.log('Apply My Leave Failed error', error);
      notification.error({
        message: isApprove? "Cannot Approve Leave" : "Cannot Submit Leave",
        duration: 0
      });
      return;
    }
    errorHandler(res, isApprove, myLeaveTabRef?.current?.reloadMyLeaveHistory);
  }

  const errorHandler = (res: any, isApprove: boolean, reloadCallback: (() => void) | undefined) => {
    if (res.messages && res.messages.length > 0) {
      // check messageType in each message
      let isWarning = false;
      let isError = false;
      res.messages.forEach((message: any) => {
        if (message.messageType === 1) {
          isWarning = true;
        }
        if (message.messageType === 0) {
          isError = true;
        }
      });
      const messageElements = res.messages.map((message: any, index: number) => (
        <div key={index}>{message.message}</div>
      ));
      messageElements.unshift(<div key='-1'>{res.response}</div>)

      if (isWarning && !isError) {
        notification.warning({
          message: isApprove ? "Leave Approved with Warning" : "Leave Application Submitted with warning",
          description: <>{messageElements}</>,
          duration: 0
        });
        if (reloadCallback) {
          reloadCallback();
        }
        setIsModalVisible(false);
        return;
      } else {
        notification.error({
          message:  isApprove ? "Cannot Approve Leave" : "Cannot Submit Leave",
          description: <>{messageElements}</>,
          duration: 0
        })};
        return;
    } else {
      notification.success({
        message: isApprove ? "Leave Approved" : "Leave Application Submitted",
        description: res.response ? res.response : '',
        duration: 0
      });
      if (reloadCallback) {
        reloadCallback();
      }
      setIsModalVisible(false);
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const changeTab = (key: LeavePan) => {
    setLeavePan(key);
  }

  const clear = () => {
    applyLeaveRef.current?.clear();
  }

  const tabItems = [
    ...(props.canManageLeave
      ? [
          {
            key: LeavePan.teamLeave,
            label: 'Team Leave',
            children: <TeamLeaveTab ref={teamLeaveTabRef} />,
            className: 'teamleave-pane',
          },
        ]
      : []),
    {
      key: LeavePan.myLeave,
      label: 'My Leave',
      children: <MyLeaveTab ref={myLeaveTabRef} />,
      className: 'myleave-pane',
    },
    {
      key: '3',
      label: 'Estimated Leave',
      children: <EstimateLeave canManage={props.canManageLeave} />,
      className: 'estimatedleave-pane',
    },
    {
      key: '4',
      label: 'Leave Calendar',
      children: <LeaveCalendar canManage={props.canManageLeave} />,
      className: 'leavecalendar-pane',
    },
  ];

  return (
    <div style={{  }} className='Leave-container'>
      <Row justify="space-between" style={{padding: '20px 20px 0 20px'}} align="middle">
        <Title level={2}>Leave</Title>
        <Button type="primary"
          style={{ fontSize: '16px' }}
          loading={buttonLoading}
          onClick={showCreateLeave}>Create</Button>
      </Row>
      <Tabs
        activeKey={leavePan}
        style={{ margin: '20px 0 0 0' }}
        onChange={(key) => changeTab(key as LeavePan)}
        items={tabItems}
      />
      <Modal className='full-screen-modal'
        title="Apply for Leave"
        open={isModalVisible} 
        onOk={() => handleOk(false)}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={
            <>
          <Button
            size={ window.innerWidth <= theme.xsScreen ?'small' : 'middle' }
            style={{ fontSize: window.innerWidth <= theme.xsScreen ? '12px' : '14px' }}
            onClick={clear} disabled={modalLoading}>
            Clear
          </Button>
          <Button type="primary"
            size={ window.innerWidth <= theme.xsScreen ?'small' : 'middle' }
            style={{ fontSize: window.innerWidth <= theme.xsScreen ? '12px' : '14px' }}
            onClick={() => handleOk(false)} disabled={modalLoading}>
            Submit
          </Button>
          {
            leavePan === 'Team Leave' &&
            <Button type="primary"
              size={ window.innerWidth <= theme.xsScreen ?'small' : 'middle' }
              style={{ fontSize: window.innerWidth <= theme.xsScreen ? '12px' : '14px' }}
              onClick={() => handleOk(true)} disabled={modalLoading}>Approve</Button>
          }
          
          <Button type="primary" onClick={handleCancel}
             danger disabled={modalLoading}
             size={ window.innerWidth <= theme.xsScreen ?'small' : 'middle' }
             style={{ fontSize: window.innerWidth <= theme.xsScreen ? '12px' : '14px' }}>
            Cancel
          </Button>
          </>
        }
        >
        <Spin spinning={modalLoading} size="large"
          indicator={<LoadingOutlined style={{ fontSize: 42 }} spin />}>
          <ApplyLeave ref={applyLeaveRef} isTeamLeave={leavePan === 'Team Leave'} isOpen={isModalVisible} isNewLeave={true} />
        </Spin>
        
      </Modal>
    </div>
  );
};

export default Leave;
