import { DefaultTeamLeaveHistoryParam, LEAVE_REQUEST_DATE_FORMAT, LeaveStatus, REQUEST_DATE_FORMAT } from '@/components/constant';
import Leave from '@/components/Leave';
import { LeaveHistoryDetails } from '@/components/types';
import remote from '@/remote/remote';
import dayjs from 'dayjs';
import { createContext, useReducer, ReactNode, Dispatch, useContext } from 'react';
// Define the shape of the state\
interface LeaveHistoryResponse {
  leaveHistory: LeaveHistoryDetails[];
  totalPages: number
  totalItems: number
}
interface State {
  leaveCodes: any[];
  employees: any[];
  managers: any[];
  forwardToManagers: any[];
  teamLeaveHistory: any[];
  leaveConfig: any; // Add new state property
  selectedLeaveDetail: any;
  allLeaveCodes: any[]; // Add new state property
  selectedLeaveUnit: any;
  isLoading: boolean;
}

// Define the initial state
const initialState: State = {
  leaveCodes: [],
  employees: [],
  managers: [],
  forwardToManagers: [],
  teamLeaveHistory: [],
  leaveConfig: {},
  selectedLeaveDetail: {},
  allLeaveCodes: [] ,
  selectedLeaveUnit: {},
  isLoading: false
};

// Define action types
type Action =
  | { type: 'SET_LEAVE_CODES'; payload: string[] }
  | { type: 'SET_EMPLOYEES'; payload: string[] }
  | { type: 'SET_FORWARD_TO_MANAGERS'; payload: string[] }
  | { type: 'SET_TEAM_LEAVE_HISTORY'; payload: any[] }
  | { type: 'SET_LEAVE_CONFIG'; payload: any }
  | { type: 'SET_SELECTED_LEAVE_DETAIL'; payload: any }
  | { type: 'SET_ALL_LEAVE_CODES'; payload: any[] }
  | { type: 'SET_SELECTED_LEAVE_UNIT'; payload: any }
  | { type: 'SET_LOADING'; payload: boolean };

// Create the reducer
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_LEAVE_CODES':
      return { ...state, leaveCodes: action.payload };
    case 'SET_EMPLOYEES':
      return { ...state, employees: action.payload };
    case 'SET_FORWARD_TO_MANAGERS':
      return { ...state, forwardToManagers: action.payload };
    case 'SET_TEAM_LEAVE_HISTORY':
      return { ...state, teamLeaveHistory: action.payload };
    case 'SET_LEAVE_CONFIG': 
      return { ...state, leaveConfig: action.payload };
    case 'SET_SELECTED_LEAVE_DETAIL':
      return { ...state, selectedLeaveDetail: action.payload };
    case 'SET_ALL_LEAVE_CODES':
      return { ...state, allLeaveCodes: action.payload };
    case 'SET_SELECTED_LEAVE_UNIT':
      return { ...state, selectedLeaveUnit: action.payload };
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

interface TeamLeaveStoreContextProps {
  state: State;
  dispatch: Dispatch<Action>;
  applySelectedLeave: (leaveStatus: number) => Promise<any>;
  updateSelectedLeave: (leaveStatus: number) => Promise<any>;
  searchLeaveHistory: ( filter: any, pageNo: number, employeeNo?: number) => Promise<LeaveHistoryResponse>;
}

// Create context
const TeamLeaveStoreContext = createContext<TeamLeaveStoreContextProps>({
  state: initialState,
  dispatch: () => undefined,
  applySelectedLeave: async () => {},
  updateSelectedLeave: async () => Promise.resolve(),
  searchLeaveHistory: async () => Promise.resolve({} as LeaveHistoryResponse),
});

interface TeamLeaveStoreProviderProps {
  children: ReactNode;
}

// Create a provider component
const TeamLeaveStoreProvider = ({ children }: TeamLeaveStoreProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const user = window.Affinity?.user;
  const applySelectedLeave = async (statusCode: LeaveStatus = LeaveStatus.Pending) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    // "EmployeeNo":"64259","PositionCode":"92000198","LeaveCode":"07","DateFrom":"22/Oct/2024","DateTo":"22/Oct/2024","ReasonCode":"Carers","TotalUnits":"7.6","SubmittedBy":43749,"Authorisations":[{"PositionCode":"92000198","SubmittedTo":"41931"}],"Comment":"","StatusCode":0,"UnitType":"H","Reply":null,"Units":[{"Date":"2024-10-22T00:00:00","PositionCode":"92000198","Hours":7.6,"HoursWorkSched":7.6,"Days":1}],"PartDayReason":""
    // dispatch({ type: 'SET_LEAVE_DETAIL', payload: selectedLeave });
    const units = formatDaysIntoUnits(state.selectedLeaveUnit);
    const params = {
      employeeNo: state.selectedLeaveDetail.employeeNo,
      positionCode: state.selectedLeaveDetail.positionCode,
      leaveCode: state.selectedLeaveDetail.leaveCode,
      dateFrom: dayjs(state.selectedLeaveDetail.dateFrom).format(LEAVE_REQUEST_DATE_FORMAT),
      dateTo: dayjs(state.selectedLeaveDetail.dateTo).format(LEAVE_REQUEST_DATE_FORMAT),
      reasonCode: state.selectedLeaveDetail.reasonCode,
      hasAttachment: state.selectedLeaveDetail.attachments?.length > 0,
      submittedBy: state.selectedLeaveDetail.employeeNo,
      authorisations: state.selectedLeaveDetail.authorisations.map((auth: any) => ({
        submittedTo: auth.employeeNo.toString(), 
        positionCode: state.selectedLeaveDetail.positionCode // Notes: use manager's position code
      })),
      comment: state.selectedLeaveDetail.comment,
      statusCode: statusCode,
      unitType: state.selectedLeaveDetail.unitType,
      reply: null,
      units: units,
      totalUnits: state.selectedLeaveDetail.unitType === 'H' 
        ? state.selectedLeaveDetail.totalHours 
        : state.selectedLeaveDetail.totalDays,
      partDayReason: state.selectedLeaveDetail.partDayReason,
    }
    const res = await remote.createLeaveApplication(state.selectedLeaveDetail.employeeNo, params)
    // state.isLoading = false;
    dispatch({ type: 'SET_LOADING', payload: false });
    if (res.data) { 
      res.data.tsGroupId = res.data.tsGroupId || res.data.tSGroupId;
    }
    
    if (res.data && state.selectedLeaveDetail.attachments?.length > 0) {
      // todo: move upload attachment to standalone function;
      // so that it will have it own error message handling
      state.selectedLeaveDetail.attachments.forEach((attachment: any) => {
        try {
          remote.createLeaveAttachment(state.selectedLeaveDetail.employeeNo, res.data.tsGroupId, attachment)
        } catch (error) {
          dispatch({ type: 'SET_LOADING', payload: false });
          throw error;
        }        
      });
    }
   
    return res;
  }

  const updateSelectedLeave = async (leaveStatus = 0) => {
    state.isLoading = true;
    let leaveUnits = [];
    if (state.selectedLeaveUnit?.units && state.selectedLeaveUnit?.units.length > 0) {
      leaveUnits = state.selectedLeaveUnit.units;
    } else {
      leaveUnits = state.selectedLeaveUnit ? state.selectedLeaveUnit : [];
    }
        
    const param = {
      payCode: state.selectedLeaveDetail.leaveCode,
      calcUnit: state.selectedLeaveDetail.unitType,
      dateFrom: dayjs(state.selectedLeaveDetail.dateFrom).format('DD-MMM-YYYY'),
      dateTo: dayjs(state.selectedLeaveDetail.dateTo).format('DD-MMM-YYYY'),
      employeeNo: state.selectedLeaveDetail.employeeNo,
      reason: state.selectedLeaveDetail.reasonCode,
      managerNotes: state.selectedLeaveDetail.managerComment,
      empComment: state.selectedLeaveDetail.comment,
      partDayReason: state.selectedLeaveDetail.partDayReason,
      timeLastModified: state.selectedLeaveDetail.timeLastModified,
      tsGroupId: state.selectedLeaveDetail.tsGroupId,
      leaveUnits: leaveUnits
      .filter((unit: any) => unit.totalHoursAppliedFor > 0 || unit.hoursAppliedFor > 0)
      .map((unit: any) => {
        // flatten the positionUnits object
        if (unit.positionUnits?.length > 0) {
          Object.keys(unit.positionUnits[0]).forEach(key => {
            unit[key] = unit.positionUnits[0][key];
          });
        }
  
        return {
          daysAppliedFor: unit.totalDaysAppliedFor ? unit.totalDaysAppliedFor : unit.daysAppliedFor,
          defaultHoursWorkScheduled: null,
          hoursAppliedFor: unit.totalHoursAppliedFor ? unit.totalHoursAppliedFor : unit.hoursAppliedFor,
          hoursWorkSched: unit.hoursWorkScheduled,
          isPublicHoliday: false,
          leaveDate: unit.date,
          positionCode: unit.positionCode,
          publicHolidayName: "",
          tsGroupId: state.selectedLeaveDetail.tsGroupId
        };
      }),
      leaveStatus: leaveStatus,
    };
    
    
    try {
      const res = await remote.updateLeaveV2(state.selectedLeaveDetail.tsGroupId, param)
      state.isLoading = false;
      dispatch({ type: 'SET_LOADING', payload: false });
      return res;
    } catch (error) {
      dispatch({ type: 'SET_LOADING', payload: false });
      throw error;
    }
  }

  const formatDaysIntoUnits = (days: []) => {
    let units = days.filter((day: any) => day.totalHoursAppliedFor > 0)
    .map((day: any) => {
      // const daysUnit = day.positionUnits[0]
      return {
        "Date": day.date,
        "PositionCode": state.selectedLeaveDetail.positionCode,
        "Hours": day.totalHoursAppliedFor,
        "HoursWorkSched": day.hoursWorkScheduled,
        "Days": day.totalDaysAppliedFor
      }
    })
    return units;
    // dispatch({ type: 'SET_SELECTED_LEAVE', payload: { ...state.selectedLeave, units } });
  }

  const searchLeaveHistory = async (
    filter: any,
    pageNo: number,
    employeeNo?: number,
  ): Promise<LeaveHistoryResponse> => {
    let selectedEmpNo = null;
    if (employeeNo) {
      selectedEmpNo = employeeNo;
    } else {
      selectedEmpNo = filter.employeeNo === "all" ? null : filter.employeeNo;
    }
    let params = {
      ...DefaultTeamLeaveHistoryParam,
      EmployeeFilter: selectedEmpNo,
      StatusCode: filter.status === "all" ? null : filter.status,
      LeaveCode: filter.leaveType === "all" ? null : filter.leaveType, // todo: change to number??
      DateFrom: filter.dateFrom ?  dayjs(filter.dateFrom).format(LEAVE_REQUEST_DATE_FORMAT) : null,
      DateTo: filter.dateTo ? dayjs(filter.dateTo).format(LEAVE_REQUEST_DATE_FORMAT): null,
      OrderBy: filter.orderBy, // date from, date to, date submitted
      includeIndirect: filter.indirect ? filter.indirect : null,
      excludeNonApprovers: filter.toAction ? filter.toAction : null,
      isAscending: filter.isAscending,
      PageNo: pageNo,
    };
    
    try {
      const res = await remote.getManagerTeamLeaveHistory(user.employeeNo, params)
      state.teamLeaveHistory = res;
      const newLeaveHistory = res.history.map(
        (leave: LeaveHistoryDetails) => ({
          employeeName: leave.employeeName,
          employeeNo: leave.employeeNo,
          dateFrom: leave.dateFrom,
          dateTo: leave.dateTo,
          leaveCode: leave.leaveCode,
          codeDescription: leave.codeDescription,
          status: leave.status,
          statusCode: leave.statusCode,
          totalDays: leave.totalDays,
          totalHours: leave.totalHours,
          unitType: leave.unitType,
          comment: leave.comment,
          authorisations: leave.authorisations,
          positionCode: leave.positionCode,
          positionTitle: leave.positionTitle,
          tsGroupId: leave.tSGroupId,
          timeSubmitted: dayjs(leave.timeSubmitted).format('DD-MMM-YYYY hh:mm A'),
          //label: leave.employeeName,
          //value: leave.employeeNo
        })
      );
      const history: LeaveHistoryResponse = {
        leaveHistory: newLeaveHistory,
        totalPages: res.pageCount,
        totalItems: res.count
      }
      return history
    } catch (error) {
      console.error("Failed to get leave history:", error);
      throw error;
    }
  };


  return (
    <TeamLeaveStoreContext.Provider value={{ state, dispatch, applySelectedLeave, updateSelectedLeave, searchLeaveHistory }}>
      {children}
    </TeamLeaveStoreContext.Provider>
  );
};

// Custom hook to use the store context more easily
const useTeamLeaveStore = () => {
  const context = useContext(TeamLeaveStoreContext);
  if (!context) {
    throw new Error('useTeamLeaveStore must be used within a StoreProvider');
  }
  return context;
};

export { TeamLeaveStoreProvider, useTeamLeaveStore };